<template>
  <div :class="className">
    <h5 class="user-select-none">Auditoría del paquete</h5>
    <ul data-table="sticky" class="table fs-08 mb-5 mb-md-0">
      <li class="encabezado row align-items-end">
        <div class="col-2 col-md-3 d-none d-sm-block">Fecha</div>
        <div class="col">Descripción</div>
        <div
          v-if="esIndividual"
          class="col col-sm-3 d-none d-sm-block"
        >
          Cliente
        </div>
        <div class="col col-sm-3 d-none d-sm-block">Autor</div>
      </li>
      <li
        v-for="(cambio, i) in cambiosPaquete"
        :key="`paquete-cambio-${i}`"
        class="contenido normal row align-items-center"
      >
        <div class="col-2 col-md-3 d-none d-sm-block">
          {{ fechaYHoraConFormato(cambio.fechaHora.formatted) }}
        </div>
        <div class="col">
          <span class="d-block d-sm-none">
            Fecha: {{ fechaYHoraConFormato(cambio.fechaHora.formatted) }}
          </span>
          Descripción:
          <span :class="{'text-muted user-select-none': !cambio.descripcion}">
            {{ cambio.descripcion ? cambio.descripcion : "Sin especificar" }}
          </span>
          <p class="mb-0" v-if="cambio.defectuoso">
            Defectuoso:
            <span>
              {{ cambio.defectuoso ? 'Si' : '' }}
            </span>
          </p>
          <p class="mb-0" v-if="cambio.pagado">
            Pagado:
            <span>
              {{ cambio.pagado ? 'Si' : '' }}
            </span>
          </p>
          <p class="mb-0">
            Estatus:
            <span :class="{'text-muted user-select-none': !cambio.nuevoEstatus}">
              {{ cambio.nuevoEstatus ? PaqueteEstatus[cambio.nuevoEstatus] : "Sin especificar" }}
            </span>
          </p>
          <p class="mb-0 d-sm-none">
            Autor:
            <span :class="{'text-muted user-select-none': !cambio.autor}">
              {{ cambio.autor ? cambio.autor : 'Sin especificar' }}
            </span>
          </p>
          <p :class="['mb-0', {'d-sm-none': esIndividual}]">
            Cliente:
            <span :class="{'text-muted user-select-none': !cambio.cliente}">
              {{ cambio.cliente ? cambio.cliente : 'Sin especificar' }}
            </span>
          </p>
        </div>
        <div
          v-if="esIndividual"
          :class="['col col-sm-3 d-none d-sm-block', {'text-muted user-select-none': !cambio.cliente}]"
        >
          {{ cambio.cliente ? cambio.cliente : 'Sin especificar' }}
        </div>
        <div :class="['col col-sm-3 d-none d-sm-block', {'text-muted user-select-none': !cambio.autor}]">
          {{ cambio.autor ? cambio.autor : 'Sin especificar' }}
        </div>
      </li>
      <li
        v-if="!(cambiosPaquete && cambiosPaquete.length)"
        class="no-items"
      >
        No hay cambios
      </li>
    </ul>
  </div>
</template>

<script>
import {PaqueteEstatus} from "@/constantes/paquetes.js"
export default {
  name: 'TablaAuditoriaPaquete',
  props: {
    cambiosPaquete: {
      type: Array,
      required: true,
    },
    className: {
      type: [String, Array],
      default: '',
    },
    esIndividual: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      PaqueteEstatus,
    }
  },
  methods: {
    // Formatear fecha y hora
    fechaYHoraConFormato(fecha){
      const fechaFormato = new Date(fecha)
      return fechaFormato.toLocaleString()
    },
  }
}
</script>

<style>

</style>